import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes, useLocation, Navigate } from "react-router-dom";

import StartupPage from "./pages/StartupPage";
import HomePage from "./pages/Home";
import NotFoundPage from "./pages/NoPage";
import TopMenu from "./components/TopMenu";
import Footer from "./components/Footer";
import GalleryPage from "./pages/GalleryPage";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import ProductListPage from "./pages/ProductListPage";
import ProductPage from "./pages/ProductPage";

import { CartProvider } from './context/Cart';
import AuthProvider from './context/Auth.js';
import ProtectedRoute from './components/ProtectedRoute';

import "./assets/fonts/republica-minor-2-0/Republica Minor 2.0.otf"
import './App.css';

const AppContent = () => {
  const location = useLocation();
  const isStartupPage = location.pathname === "/";
  const isGalleryPage = location.pathname ==="/gallery";

  return (
    <div className="app">
      {!isStartupPage && <TopMenu />}
      <div className="main-content">
        <Suspense fallback={<div className="text-white text-center mt-3">Loading...</div>}>
          <Routes>
            <Route exact path="/" element={<StartupPage />} />
            {/* Protect all other routes */}
            <Route exact path="/home" element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            } />
            <Route path="/products" element={<Navigate to="/products/all" replace />} />
            <Route exact path="/products/:collection" element={
              <ProtectedRoute>
                <ProductListPage />
              </ProtectedRoute>
            } />
            <Route exact path="/contact" element={
              <ProtectedRoute>
                <ContactPage />
              </ProtectedRoute>
            } />
            <Route exact path="/about" element={
              <ProtectedRoute>
                <AboutPage />
              </ProtectedRoute>
            } />
            <Route exact path="/gallery" element={
              <ProtectedRoute>
                <GalleryPage />
              </ProtectedRoute>
            } />
            <Route path="/product/:id" element={
              <ProtectedRoute>
                <ProductPage />
              </ProtectedRoute>
            } />
            <Route path="*" element={
              <ProtectedRoute>
                <NotFoundPage />
              </ProtectedRoute>
            } />
          </Routes>
        </Suspense>
      </div>
      {!isStartupPage && !isGalleryPage && <Footer className="footer" />}
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <CartProvider>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </CartProvider>
    </BrowserRouter>
  );
}

export default App;