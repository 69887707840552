import React, { useState, useContext } from 'react';
import { Button, Container, Row, Col, Dropdown } from 'react-bootstrap';

import { CartContext } from '../context/Cart';

import '../styles/components/selectableVariants.css';

const SelectableVariants = ({ variants }) => {
  // Filter out unavailable variants
  const availableVariants = variants.filter(variant => variant.available);

  // Initialize state with the first available variant
  const [selectedVariant, setSelectedVariant] = useState(availableVariants[0] || null);

  const { addToCart } = useContext(CartContext);

  const handleAddToCart = (variant) => {
    addToCart(variant);
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          {availableVariants.length > 0 ? (
            <Dropdown drop="up">
              <Dropdown.Toggle size="sm" className="product-variant-dropdown" variant="outline-dark" id="dropdown-basic">
                {selectedVariant?.title || "Select an option"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {availableVariants.map((variant, variantIdx) => (
                  <Dropdown.Item
                    key={variantIdx}
                    onClick={() => setSelectedVariant(variant)}
                  >
                    {variant.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <p className="text-muted soldout-text">SOLD OUT</p>
          )}
        </Col>
        {availableVariants.length > 0 && (
          <Col md={12}>
            <Button
              className="add-to-cart-btn mt-2 mb-2"
              onClick={() => handleAddToCart(selectedVariant)}
              variant="dark"
              disabled={!selectedVariant}
              size="sm"
            >
              Add to Cart
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default SelectableVariants;