import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Toast, ToastContainer } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

const ContactPage = () => {
    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'auto'
        }, 500);
    }, []);

    // Create a reference to the form element
    const form = useRef();

    // State to store form values
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: ''
    });

    // State to store form submission status and toast visibility
    const [status, setStatus] = useState('');
    const [showToast, setShowToast] = useState(false);

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation to check if all fields are filled
        if (!formData.firstName || !formData.lastName || !formData.email || !formData.subject || !formData.message) {
            alert('All fields are required.');
            return;
        }
        setStatus('MESSAGE WAS SENT');
        setShowToast(true);  // Show the toast for success

        // Send email using EmailJS
        emailjs
            .sendForm(process.env.REACT_APP_EMAIL_JS_SERVICE, process.env.REACT_APP_EMAIL_JS_TEMPLATE, form.current, process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY)
            .then(
                () => {
                    setStatus('Message sent successfully!');
                    setShowToast(true);  // Show the toast for success
                    // Clear the form after successful submission
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        subject: '',
                        message: ''
                    });
                },
                (error) => {
                    setStatus(`Failed to send message: ${error.text}`);
                    setShowToast(true);  // Show the toast for error
                }
            );
    };

    return (
        <Container className='fonty' style={{ paddingTop: "16vh", paddingBottom: "6vh" }}>
            <h2>Contact Us</h2>
            
            {/* Toast for status message */}
            <ToastContainer position="middle-center" className="p-3">
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                    <Toast.Body className='text-center'>{status}</Toast.Body>
                </Toast>
            </ToastContainer>

            <Form ref={form} onSubmit={handleSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="formFirstName" className='mt-3'>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your first name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formLastName" className='mt-3'>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your last name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group controlId="formEmail" className="mt-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formSubject" className="mt-3">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter the subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formMessage" className="mt-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter your message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Button variant="outline-dark" type="submit" className="mt-4">
                    Submit
                </Button>
            </Form>
        </Container>
    );
};

export default ContactPage;