import { useContext } from "react";
import { Image, Container, Row, Col, Button, Stack } from 'react-bootstrap';
import StockPhoto from '../assets/images/DisorderUnderMenuGraphic.png';
import '../styles/components/cartItemStyles.css';
import { CartContext } from "../context/Cart";

function CartItem({ variant }) {
    const { removeFromCart } = useContext(CartContext);

    const handleRemoveItemFromCartClick = (variant) => {
        removeFromCart(variant);
    };

    return (
        <Container>
            <Row>
                <Col>
                    <Image className="cart-item-image" fluid src={variant.image?.src || StockPhoto} />
                </Col>
                <Col>
                    <Stack>
                        <div className="stack-item">
                            {variant.title}
                        </div>
                        <div className="stack-item">
                            QTY: {variant.cartQuantity}
                        </div>
                        <div className="stack-item">
                            <Button onClick={() => handleRemoveItemFromCartClick(variant)} className="custom-button" variant="outline-dark" size="sm">Remove</Button>
                        </div>
                    </Stack>
                </Col>
                <Col>
                    ${variant.price.amount * variant.cartQuantity}
                </Col>
            </Row>
        </Container>
    );
}

export default CartItem;