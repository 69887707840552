import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);  // Track loading state
  const token = localStorage.getItem('token');

  useEffect(() => {
    // const verifyToken = async () => {
    //   if (!token) {
    //     setIsTokenValid(false);
    //     setIsLoading(false); // Stop loading if no token
    //     return;
    //   }

    //   try {
    //     const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/auth/verifyAuthToken`, {
    //       method: 'GET',
    //       headers: {
    //         'Authorization': `Bearer ${token}`,  // Send the token in the Authorization header
    //         'Content-Type': 'application/json',
    //       },
    //     });

    //     if (response.ok) {
    //       setIsTokenValid(true);  // Token is valid
    //     } else {
    //       setIsTokenValid(false); // Token is invalid
    //     }
    //   } catch (error) {
    //     console.error('Token verification failed:', error);
    //     setIsTokenValid(false); // Token is invalid or request failed
    //   }

    //   setIsLoading(false); // Stop loading after token verification
    // };

    // verifyToken();
  }, [token]);

  // Show a loading indicator while verifying the token
  if (isLoading) {
    return <div>Loading...</div>;  // You can replace this with a spinner or a custom loading component
  }

  // Redirect to login if token is invalid
  if (!isTokenValid) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;