import { createContext, useEffect, useState, useContext } from 'react';

// Create AuthContext
const AuthContext = createContext();

// Custom hook to use the Auth context
export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    // const verifyToken = async () => {
    //   const token = localStorage.getItem('token');
    //   if (!token) {
    //     setIsAuthenticated(false);
    //     return;
    //   }

    //   try {
    //     // Verify token using the backend
    //     const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/auth/verifyAuthToken`, {
    //       method: 'GET',
    //       headers: {
    //         'Authorization': `Bearer ${token}`,  // Format Authorization as Bearer <token>
    //         'Content-Type': 'application/json',
    //       },
    //     });
    //     if (response.ok) {
    //       setIsAuthenticated(true);  // Token is valid
    //     } else {
    //       setIsAuthenticated(false); // Token is invalid
    //     }
    //   } catch (error) {
    //     console.error('Token verification failed:', error);
    //     setIsAuthenticated(false); // Token is invalid or request failed
    //   }
    // };

    // verifyToken();
  }, []);

  const login = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;