import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Image, Button, Form, InputGroup, Alert, Container } from 'react-bootstrap';
import StartupImage from '../assets/images/DisorderEntryGraphic.jpeg';
import '../styles/pages/startupPage.css';
import { useAuth } from '../context/Auth';

const StartupPage = () => {
    const [password, setPassword] = useState('');
    const [showAlert, setShowAlert] = useState(false);  // State for showing the alert
    const { login, isAuthenticated } = useAuth();  // Get isAuthenticated from context
    const navigate = useNavigate();

    // Check password function to get token from the backend
    const checkPassword = async (pwd) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/auth/attemptPasswordPageLogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password: pwd }),
            });

            if (!response.ok) {
                return null;
            }

            const data = await response.json();
            return data.token;
        } catch (error) {
            console.error('Error during login attempt:', error);
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = await checkPassword(password);  // Get token from backend

        if (token) {
            login(token);  // Store token on successful login
            navigate('/home');  // Redirect to home page
        } else {
            setPassword('');  // Clear password input
            setShowAlert(true);  // Show alert for wrong password
        }
    };

    return (
        <Container fluid className="startup-container p-0">
            <Link to="/home">
                <Image
                    fluid 
                    src={StartupImage} 
                    style={{ width: '100vw', height: '100vh', objectFit: 'cover' }} 
                    rounded 
                />
                <div className="button-container">
                    ENTER SITE
                </div>
            </Link>
            <div className="password-overlay overlay-text">
                {showAlert && (
                    <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                        WRONG PASSWORD.
                    </Alert>
                )}

                {/* Only show the password form if the user is not authenticated */}
                {!isAuthenticated && (
                    <Form onSubmit={handleSubmit} className="password-form pwd-box">
                        <InputGroup>
                            <Form.Control
                                type="password"
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="password-input"
                            />
                            <Button type="submit" className="password-submit" variant="light">
                                Submit
                            </Button>
                        </InputGroup>
                    </Form>
                )}
            </div>
        </Container>
    );
};

export default StartupPage;