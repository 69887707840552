import { createContext, useState, useEffect, useCallback } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : []);
  const [checkoutId, setCheckoutId] = useState(localStorage.getItem('checkoutId') ? localStorage.getItem('checkoutId') : null);
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false);
  
  // Memoize checkCartCompletion using useCallback
  const checkCartCompletion = useCallback(async () => {
    if (checkoutId && checkoutId !== null) {
      const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/shopify/getCheckout?checkoutId=${checkoutId}`);

      // Assuming you are expecting a JSON response, handle it accordingly
      const data = await response.json();
      const completedCheckout = data.completedCheckout;

      return completedCheckout;
    } else {
      return false;
    }
  }, [checkoutId]); // Add checkoutId as a dependency

  const clearCart = () => {
    setCartItems([]);
  };

  useEffect(() => {
    const handleCheckout = async () => {
      const isCompleted = await checkCartCompletion();
  
      if (isCompleted === true) {
        clearCart();
        setCheckoutId(null);
        console.log('found checkout');
      } else {
        console.log('did not find checkout');
      }
    };
  
    handleCheckout();
  }, [checkoutId, checkCartCompletion]); // checkCartCompletion is now stable between renders


  const addToCart = async (variant) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === variant.id);

    if (isItemInCart) {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.id === variant.id
            ? { ...cartItem, cartQuantity: cartItem.cartQuantity + 1 }
            : cartItem
        )
      );
    } else {
      setCartItems([...cartItems, { ...variant, cartQuantity: 1 }]);
    }

    setIsCartDrawerOpen(true);
  };

  const removeFromCart = async (variant) => {
    const isVariantInCart = cartItems.find((cartVariant) => cartVariant.id === variant.id);
    if (isVariantInCart.cartQuantity === 1) {
      setCartItems(cartItems.filter((cartVariant) => cartVariant.id !== variant.id));
    } else {
      setCartItems(
        cartItems.map((cartVariant) =>
          cartVariant.id === variant.id
            ? { ...cartVariant, cartQuantity: cartVariant.cartQuantity - 1 }
            : cartVariant
        )
      );
    }
  };

  const getCartTotal = () => {
    return cartItems.reduce((total, variant) => total + variant.price.amount * variant.cartQuantity, 0);
  };

  const getCartCount = () => {
    var cartTotal = 0;

    cartItems.forEach(cartItem => {
      cartTotal += cartItem.cartQuantity;
    });

    return cartTotal;
  };

  const toggleCartDrawer = () => {
    setIsCartDrawerOpen((prev) => !prev);
  }

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const cartItems = localStorage.getItem('cartItems');
    if (cartItems) {
      setCartItems(JSON.parse(cartItems));
    }
  }, []);

  useEffect(() => {
    if (checkoutId !== null) {
      localStorage.setItem('checkoutId', checkoutId);
    } else {
      localStorage.removeItem('checkoutId'); // Or just don't store it
    }
  }, [checkoutId]);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        //checkoutId,
        //checkoutUrl,
        addToCart,
        removeFromCart,
        clearCart,
        getCartTotal,
        getCartCount,
        setCheckoutId,
        isCartDrawerOpen,
        toggleCartDrawer,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};