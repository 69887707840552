import { Stack, Image, Spinner } from 'react-bootstrap';
import { useState } from 'react';

const galleryMediaPaths = [
    '/images/Gallery/junkyTargetImage0.jpeg',
    '/images/Gallery/junkyTargetImage1.jpeg',
    '/images/Gallery/junkyTargetImage2.jpeg',
    '/images/Gallery/junkyTargetImage3.jpeg',
    '/images/Gallery/upseto1.jpeg',
    '/images/Gallery/upseto2.jpeg',
    '/images/Gallery/upseto3.jpeg',
    '/images/Gallery/upseto4.jpeg',
    '/images/Gallery/upseto5.jpeg',
    '/images/Gallery/image00009.jpeg',
    '/images/Gallery/image00024.jpeg',
    '/images/Gallery/image00025.jpeg',
    '/images/Gallery/image00026.jpeg',
    '/images/Gallery/image00029.jpeg',
    '/images/Gallery/image00001.jpeg',
    '/images/Gallery/image00002.jpeg',
    '/images/Gallery/image00003.jpeg',
    '/images/Gallery/image00004.jpeg',
    '/images/Gallery/image00005.jpeg',
    '/images/Gallery/image00006.jpeg',
    '/images/Gallery/image00007.jpeg',
    '/images/Gallery/image00008.jpeg',
    '/images/Gallery/image00010.jpeg',
    '/images/Gallery/image00011.jpeg',
    '/images/Gallery/image00012.jpeg',
    '/images/Gallery/image00013.jpeg',
    '/images/Gallery/image00014.jpeg',
    '/images/Gallery/image00015.jpeg',
    '/images/Gallery/image00016.jpeg',
    '/images/Gallery/image00017.jpeg',
    '/images/Gallery/image00018.jpeg',
    '/images/Gallery/image00019.jpeg',
    '/images/Gallery/image00020.jpeg',
    '/images/Gallery/image00021.jpeg',
    '/images/Gallery/image00023.jpeg',
    '/images/Gallery/image00027.jpeg',
    '/images/Gallery/image00028.jpeg',
    '/images/Gallery/image00022.jpeg'
];

const GalleryPage = () => {
    // State for tracking loaded media
    const [loaded, setLoaded] = useState(Array(galleryMediaPaths.length).fill(false));

    // Mark media as loaded
    const handleMediaLoad = (index) => {
        setLoaded((prevState) => {
            const newLoadedState = [...prevState];
            newLoadedState[index] = true;
            return newLoadedState;
        });
    };

    return (
        <Stack>
            {/* Render media dynamically */}
            {galleryMediaPaths.map((path, index) => {
                const isVideo = path.endsWith('.mp4'); // Check if the file is a video
                return (
                    <div key={index} style={{ position: 'relative' }}>
                        {!loaded[index] && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}
                            >
                                <Spinner animation="border" />
                            </div>
                        )}
                        {isVideo ? (
                            <video
                                width="100%"
                                controls
                                onLoadedData={() => handleMediaLoad(index)}
                                style={{ display: loaded[index] ? 'block' : 'none' }}
                            >
                                <source src={path} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <Image
                                fluid
                                src={path}
                                style={{ width: '100vw', objectFit: 'cover' }}
                                onLoad={() => handleMediaLoad(index)}
                            />
                        )}
                    </div>
                );
            })}
        </Stack>
    );
};

export default GalleryPage;