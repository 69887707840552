import { useContext } from 'react';
import { CartContext } from '../context/Cart';
import { Offcanvas, Container, Row, Col, Stack, Button } from 'react-bootstrap';
import CartItem from './CartItem';
import '../styles/components/cartDrawerStyles.css';

const CartDrawer = ({ isOpen, toggleDrawer }) => {
    const { cartItems, getCartCount, getCartTotal, setCheckoutId, toggleCartDrawer, isCartDrawerOpen } = useContext(CartContext);

    const handleCheckoutClick = async () => {
        try {
            // Generate the checkout link
            const checkoutUrl = await generateCheckoutUrl();
    
            // Navigate to the generated URL
            window.location.href = checkoutUrl;
        } catch (error) {
            console.error('Error generating checkout URL:', error);
        }
    };

    const generateCheckoutUrl = async () => {
        const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/shopify/createCheckout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({items: cartItems}),
        });
        const data = await response.json();

        const checkoutUrl = data.checkoutUrl;
        const checkoutId = data.checkoutId;

        setCheckoutId(checkoutId);
        return checkoutUrl;
    };

    return (
        <Offcanvas className='ofc-width cart-drawer-font' show={isCartDrawerOpen} onHide={toggleCartDrawer} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>CART ({getCartCount()})</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Stack>
                    {cartItems.map((inCartItem, inCartItemIdx) => (
                        <div className='m-2' key={inCartItemIdx}>
                            <CartItem variant={inCartItem} />
                        </div>
                    ))}
                </Stack>
                <Container className='cart-drawer-footer'>
                    <Row className='mb-2'>
                        <Col style={{ textAlign: 'right' }}>
                            SUBTOTAL: ${getCartTotal()}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-grid gap-2'>
                            <Button variant='outline-dark' disabled={cartItems.length === 0} onClick={() => handleCheckoutClick()}>
                                Checkout
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CartDrawer;