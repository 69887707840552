import React, { useState } from 'react';
import { Container, Row, Col, Stack, Image, Form, Button, FormControl, InputGroup, Toast, ToastContainer } from 'react-bootstrap';

// css
import '../styles/components/footer.css';

// assets
import DisorderWorldWideLogo from "../assets/images/DisorderWorldWideLogoWhite.png";
import DisorderLargeText from "../assets/images/DisorderTimeCapsuleImageWhite.png";
import InstagramLogo from "../assets/images/instagram_logo.png";
import TikTokLogo from "../assets/images/tiktok_logo.png";

const Footer = () => {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(null); // Null means no validation attempt yet
    const [message, setMessage] = useState('');
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [showToast, setShowToast] = useState(false); // State to control toast visibility

    const handleNewsletterClick = async (event) => {
        event.preventDefault(); // Prevent the form from actually submitting
    
        // Email validation regex
        const emailRegex = /^\S+@\S+\.\S+$/;
    
        // Check if the email is valid
        if (!emailRegex.test(email)) {
            setIsValidEmail(false);
            return;
        }
    
        setIsValidEmail(true);
    
        try {
            // Send email information using fetch
            const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/mailchimp/subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
    
            if (response.ok) {
                setIsSubscribed(true); // Disable the input and button
                setMessage('Successfully subscribed to the newsletter!');
            } else {
                const errorData = await response.json();
                // Check if the error message is about the email already being subscribed
                if (errorData.error === 'This email is already subscribed to the list') {
                    setMessage('This email is already subscribed to the list');
                } else {
                    setMessage('Failed to subscribe. Please try again later.');
                }
            }
            setShowToast(true); // Show the toast regardless of success or error
        } catch (error) {
            console.error('Error:', error);
            setMessage('Failed to subscribe. Please try again later.');
            setShowToast(true);
        }
    };

    return (
        <Container style={{ marginTop: "10px" }} fluid className='d-flex flex-column justify-content-center footer-font footer-bg'>
            {/* Toast notification */}
            <ToastContainer position="top-center" className="p-3">
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
            </ToastContainer>

            <Row className="mt-3">
                <Col>
                    <Form className='form-styling' onSubmit={handleNewsletterClick}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className='footer-email-list-text footer-text-dude'>
                                {isSubscribed ? "SUBSCRIBED SUCCESSFULLY!" : "SUBSCRIBE"}
                            </Form.Label>
                            <InputGroup hasValidation>
                                <FormControl
                                    className='custom-placeholder form-email-list-box-text'
                                    type="email"
                                    placeholder="ENTER YOUR EMAIL"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    isInvalid={isValidEmail === false} // Controls validation state
                                    disabled={isSubscribed} // Disable the input if subscribed
                                />
                                <Button size='sm' variant="light" type="submit" disabled={isSubscribed}>
                                    Submit  
                                </Button>
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email address.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <Stack direction="vertical">
                        <div className='p-2 d-flex align-items-center'>
                            <Image className='footer-social-logo' src={InstagramLogo} />
                            <a className='footer-social-link footer-text ms-2' href="https://www.instagram.com/disordercapsule/" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
                        </div>
                        <div className='p-2 d-flex align-items-center'>
                            <Image src={TikTokLogo} style={{ width: "2vw" }} />
                            <a className='footer-social-link footer-text text-center ms-2' href="https://www.tiktok.com/@junkmail.mp3" target="_blank">TIKTOK</a>
                        </div>
                    </Stack>
                </Col>
            </Row>

            <Row className='mt-3'>
                <Col xs={6} md={6}>
                    <Image className='footer-image-left' fluid src={DisorderWorldWideLogo} />
                </Col>
                <Col className='position-relativeddd' xs={6} md={6}>
                    <Image
                    fluid
                    src={DisorderLargeText}
                    className='anchor-bottom-rightddd footer-image-right'
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default Footer;