import { Container, Col, Row, Image } from 'react-bootstrap';
import { useEffect } from 'react';

import '../styles/pages/aboutPage.css';


import InfoImg from '../assets/images/DisorderInfoComingSoon.png';

const AboutPage = () => {
    useEffect(() => {
        const body = document.querySelector('#root');
    
        body.scrollIntoView({
            behavior: 'auto'
        }, 500)
    
    }, []);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Image className='about-page-img' src={InfoImg} />
                </Col>
            </Row>
        </Container>
    );
};

export default AboutPage;