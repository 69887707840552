import React from 'react';
import { Offcanvas, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../styles/components/menuDrawerStyles.css';

const MenuDrawer = ({ isOpen, toggleDrawer }) => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        const isSamePath = window.location.pathname.startsWith(path);
        
        navigate(path, { replace: true });
    
        if (!isSamePath) {
            window.location.reload();
        }
    
        toggleDrawer(); // Close the drawer after navigation
    };

    return (
        <Offcanvas show={isOpen} onHide={toggleDrawer} placement="start" className="offcanvas-menu menu-drawer-font ofc-width">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title style={{width: '100%' }}>MENU</Offcanvas.Title>
            </Offcanvas.Header>
            <hr style={{ border: '1px solid #000', width:"80%", margin:"auto" }} />
            <Offcanvas.Body>
                <Stack style={{ textAlign: "left", marginLeft: "10px" }} gap={4}>
                    <div 
                        onClick={() => handleNavigation('/products/all')}
                        className='menu-collection-option' 
                        style={{ marginTop: "30px" }}
                    >
                        ALL
                    </div>
                    <div 
                        onClick={() => handleNavigation('/products/tops')} 
                        className='menu-collection-option'
                    >
                        TOPS
                    </div>
                    <div 
                        onClick={() => handleNavigation('/products/bottoms')}
                        className='menu-collection-option'
                    >
                        BOTTOMS
                    </div>
                    <div 
                        onClick={() => handleNavigation('/products/fleece')}
                        className='menu-collection-option'
                    >
                        FLEECE
                    </div>
                    <div 
                        onClick={() => handleNavigation('/products/hats')}
                        className='menu-collection-option'
                    >
                        HATS
                    </div>
                    <div 
                        onClick={() => handleNavigation('/products/tees')}
                        className='menu-collection-option'
                    >
                        TEES
                    </div>
                    <div 
                        onClick={() => handleNavigation('/products/accessories')}
                        className='menu-collection-option'
                    >
                        ACCESSORIES
                    </div>
                </Stack>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default MenuDrawer;